@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/lato-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/lato-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/lato-italic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Playfair';
  src: url('../assets/fonts/playfairdisplay-bolditalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}


$colorBlue: #3e83ca;
$colorBlueAlt: #436cae;
$colorBlueAltDark: #2b538f;
$colorBlueDark: #175e9d;
$colorBlueDarker: #00285b;
$colorBlueLight: #ecf3fa;
$colorBlueLighter: #f2f5fb;
$colorBrightness_7: #111;
$colorBrightness_13: #222;
$colorBrightness_20: #333;
$colorBrightness_33: #555;
$colorBrightness_40: #666;
$colorBrightness_53: #888;
$colorBrightness_70: #b3b3b3;
$colorBrightness_87: #ddd;
$colorBusinessLinen: #fcf4ec;
$colorBusinessLinenDark: #f4ebe2;

$colorGreen: #390;
$colorGreenDark: #197b00;

$colorGrayBackgroundKlarna: #f2f2f1;
$colorGrayBackgroundLight: #f7f6f5;
$colorGray: #d6d6d6;
$colorGrayLight: #e8e8e8;
$colorGrayLighter: #f8f8f8;
$colorMagenta: #c3227d;
$colorMagentaAlt: #c6579a;
$colorOrange: #ff5f0e;
$colorOrangeDark: #f45200;
$colorRed: #f30;
$colorRedLight: #ffeaea;
$colorRedDark: #c80000;
$colorYellow: #ffcd00;
$colorYellowHighligt: #fffc88;
$colorYellowBackground: #fcf5e5;
$colorGridBorder: rgba(0, 0, 0, 0.1);
$colorGridBorderOpaque: #e5e5e5;

$colorText: #222;

$colorDarkBackground: #101010;

$boxShadow: 0 5px 25px rgba(0, 0, 0, 0.2);
$borderColor: rgb(214, 214, 214);
// font sizes
$text-small: 12px;
$text-normal: 14px;

// new styles

$whiteColor: #ffffff;
$blackColor: #000000;
$linkColor: #2779bd;
$indigoColor: #142581;
$indigoDarkColor: #081662;
$indigoLightColor: #7983b8;
$borderRedColor: #ca4a35;
$lightBlueGreyColor: #dde0ed;
$greyGhostColor: #edeef4;

$lightBlueColor: #6cb2eb;
$lighterBlueColor: #bcdefa;
$blueColor: #3490dc;
$darkBlueColor: #1c3d5a;

$greenColor: #38c172;
$darkGreenColor: #1f9d55;

$lightGreyColor: #8795a1;

$backgroundColor: #f9f9fa;

$aliceBlue: #eff8ff;
$yellowColor: #ffed4a;
$lightYellowColor: #fff9c2;
$lightGreyColor: #b8c2cc;
$darkGreyColor: #606f7b;
$lightRedColor: #ef5753;
$redColor: #e3342f;
$darkRedColor: #621b18;
$orangeColor: #de751f;
$lightOrangeColor: #fcd9b6;
$redHoverColor: #a83e2c;

$mainFont: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;

@mixin recommendForm {
  max-width: 40rem;
  margin: auto;
  color: $whiteColor;
  display: flex;
  flex-direction: column;
}

@mixin hashLink {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 2rem;
  text-decoration: none;
}

@mixin formTitle {
  color: $whiteColor;
  font-size: 1.875rem;
  font-weight: 600;
  &::after {
    color: $borderRedColor;
    content: '.';
  }
}

@mixin textField {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0;
  margin-top: 0.5rem;
  font-weight: 500;
  label {
    color: $whiteColor;
  }
}

@mixin applyButton {
  margin-top: 2rem;
  width: 15rem;
  color: $whiteColor;
  outline: none;
}

@mixin formInput {
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
}

@mixin loginFormContainer {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $indigoColor;
  margin-top: -4rem;
  width: 312px;
  border-radius: 5px;
}

@mixin loginForm {
  max-width: 25rem;
  padding: 0.5rem;
  padding-top: 3rem;
  margin: auto;
  display: flex;
  flex-direction: column;
}

@mixin fieldLabel {
  text-transform: uppercase;
  font-size: 0.75rem;
  text-align: left;
  color: $indigoLightColor;
}
@mixin errorMessage {
  text-align: left;
  color: $borderRedColor;
  font-size: 0.875rem;
  padding-bottom: 0.5rem;
}

@mixin visibilityIcon {
  height: 1rem;
  width: 1rem;
  background-color: $whiteColor;
  color: $indigoDarkColor;
  padding: 0;
}
