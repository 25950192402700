@import '~styles/normalize.scss';
@import '~styles/styles.module.scss';

html {
  line-height: 1.5;
}

body {
  height: 100vh;
  margin: 0;
  background-color: $backgroundColor;
  font-family: $mainFont;
}

#root {
  height: 100%;
}
